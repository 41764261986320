table {
    font-family: monospace;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid var(--spectrum-global-color-gray-300);
    padding: 8px;
    max-width: 100%;
    white-space: nowrap;
}

table tr {
    background-color: white;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--spectrum-global-color-gray-300);
}