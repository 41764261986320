* {
    --color-primary: #009FE3;
    --color-accent: #EA1D89;
    --color-accent--light: #ff69cb;
    --color-accent--lighter: #ffe2ea;

    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button[class^="_spectrum-Button"],
button[class^="_spectrum-ActionButton_"] {
    cursor: pointer;
}

.custom-scroll-bar::-webkit-scrollbar {
    width: 0.5em;
}

.custom-scroll-bar::-webkit-scrollbar-track {
    background-color: var(--spectrum-alias-background-color-default);
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #E6E6E6;
    outline: 1px solid #E6E6E6;
}

.unscrollable {
    height: 100%;
    overflow: hidden;
}
