.nav-item {
    text-decoration: none;
}

.nav-item-inner {
    border-bottom-right-radius: 1.2em;
    border-top-right-radius: 1.2em;
    color: var(--spectrum-alias-text-color);
    display: flex;
    font-size: 1.2em;
    height: 2.4em;
    padding-left: 0.6em;
    transition: background-color 150ms, color 150ms;
    width: 100%;
}

.nav-item-inner svg {
    fill: var(--spectrum-alias-text-color);
    transition: fill 150ms;
}

.nav-item-inner:hover {
    background-color: var(--spectrum-global-color-gray-200);
    color: var(--spectrum-alias-text-color-hover);
}

.nav-item-inner:hover svg {
    fill: var(--spectrum-alias-text-color);
}

.nav-item--selected .nav-item-inner {
    background-color: var(--color-accent--lighter);
    color: var(--color-accent);
    font-weight: bold;
}

.nav-item--selected svg {
    fill: var(--color-accent);
}

.nav-item--selected:hover svg {
    fill: var(--color-accent);
}
